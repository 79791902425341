// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "t_fP d_fP d_by d_bG d_bH";
export var navbarDividedRight = "t_fQ d_fQ d_by d_bH";
export var menuDesign6 = "t_sJ d_fL d_by d_dv d_bN d_bH";
export var menuDesign7 = "t_sK d_fL d_by d_dv d_bN d_bH";
export var menuRight = "t_sL d_fL d_by d_dv d_bN d_bH";
export var menuLeft = "t_sM d_fL d_by d_dv d_bN d_bH";
export var menuCenter = "t_sN d_fM d_fL d_by d_dv d_bN d_v d_bC d_bH";
export var menuDivided = "t_qB d_fM d_fL d_by d_dv d_bN d_v d_bC";
export var menuDesign5 = "t_qM d_fN d_fL d_by d_dv d_bN d_bH";
export var isBurger = "t_sP";
export var navbarItem = "t_qC d_bw";
export var navbarLogoItemWrapper = "t_fX d_fX d_bB d_bN";
export var burgerToggleVisibleOpen = "t_sQ d_gc d_bx d_Y d_br";
export var burgerToggleVisible = "t_sR d_gc d_bx d_Y d_br";
export var burgerToggle = "t_sS d_gc d_bx d_Y d_br d_Y";
export var burgerToggleOpen = "t_sT d_gc d_bx d_Y d_br";
export var burgerInput = "t_sV d_f1 d_v d_G d_bx d_cb d_dk d_bc d_Z d_7 d_6 d_3 d_8";
export var burgerIcon = "t_sW d_f2 d_v d_G";
export var burgerLine = "t_sX d_f0";
export var burgerMenuDesign6 = "t_sY d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign7 = "t_sZ d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuLeft = "t_s0 d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign5 = "t_s1 d_f7 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuRight = "t_s2 d_f7 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuCenter = "t_s3 d_f8 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDivided = "t_s4 d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var secondary = "t_s5 d_bB d_bN";
export var staticBurger = "t_s6";
export var menu = "t_s7";
export var navbarDividedLogo = "t_s8";
export var nav = "t_s9";