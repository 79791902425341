// extracted by mini-css-extract-plugin
export var alignDiscLeft = "l_pK d_fn d_bF d_dt";
export var alignLeft = "l_pL d_fn d_bF d_dt";
export var alignDiscCenter = "l_pM d_fp d_bC d_dv";
export var alignCenter = "l_bN d_fp d_bC d_dv";
export var alignDiscRight = "l_pN d_fq d_bG d_dw";
export var alignRight = "l_pP d_fq d_bG d_dw";
export var footerContainer = "l_pQ d_dV d_bV";
export var footerContainerFull = "l_pR d_dS d_bV";
export var footerHeader = "l_kd d_kd";
export var footerTextWrapper = "l_pS d_v";
export var footerDisclaimerWrapper = "l_kl d_kl d_cj";
export var badgeWrapper = "l_pT d_v d_by d_bN d_bD d_bH";
export var footerDisclaimerRight = "l_pV undefined";
export var footerDisclaimerLeft = "l_pW undefined";
export var verticalTop = "l_pX d_by d_bH d_bM d_bK";
export var firstWide = "l_pY";
export var disclaimer = "l_pZ d_by d_bH";
export var socialDisclaimer = "l_p0";
export var left = "l_p1";
export var wide = "l_p2 d_cx";
export var right = "l_p3 d_bJ";
export var line = "l_fj d_fk d_ct";
export var badgeDisclaimer = "l_p4 d_bB d_bN d_bH";
export var badgeContainer = "l_p5 d_by d_bG d_bN";
export var badge = "l_p6";
export var padding = "l_p7 d_c6";
export var end = "l_p8 d_bG";
export var linkWrapper = "l_p9 d_dz";
export var link = "l_mB d_dz";
export var colWrapper = "l_qb d_cw";
export var consent = "l_g d_g d_bB d_bN";
export var media = "l_qc d_bw d_dx";
export var itemRight = "l_qd";
export var itemLeft = "l_qf";
export var itemCenter = "l_qg";
export var exceptionWeight = "l_qh q_rs";