// extracted by mini-css-extract-plugin
export var tileContent = "s_ss d_v d_G d_Y";
export var teamTextLeft = "s_st d_dt";
export var teamTextCenter = "s_sv d_dv";
export var teamTextRight = "s_sw d_dw";
export var alignLeft = "s_pL d_v d_by d_fn d_bF d_dt";
export var alignCenter = "s_bN d_v d_by d_fp d_bC d_dv";
export var alignRight = "s_pP d_v d_by d_fq d_bG d_dw";
export var teamContainer = "s_sx d_dV";
export var teamContainerFull = "s_sy d_dS";
export var teamRowWrapper = "s_sz d_cb";
export var teamTileWrapper = "s_j2 d_j2 d_Y d_ct";
export var teamTileSquareWrapper = "s_sB d_j3 d_Y d_ct";
export var teamTileRoundWrapper = "s_j3 d_j3 d_Y d_ct";
export var teamTileNoGuttersWrapper = "s_j4 d_j4 d_Y";
export var teamHoverNoGutters = "s_j5 d_j5 d_Z d_v d_G d_by d_bC d_bN d_bl";
export var teamImageText = "s_kb d_kb d_bk d_v d_bx";
export var teamInfoWrapperSquare = "s_sC d_j6";
export var teamInfoWrapperRound = "s_j6 d_j6";
export var teamInfoWrapper = "s_sD d_j7 d_Z";
export var teamInfoWrapperNoGutters = "s_j8 d_j8 d_v d_G d_bC d_bL d_bH d_c6";
export var teamImgWrapper = "s_jY d_jY";
export var teamImgWrapperAlt = "s_jZ d_jZ";
export var teamImgWrapperNoGutters = "s_j9 d_j9";
export var teamHeader = "s_sF d_cv";
export var teamHeaderAlt = "s_sG d_cv";
export var teamHeaderNoGutters = "s_sH d_cv d_cC";