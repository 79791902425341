// extracted by mini-css-extract-plugin
export var lbContainerOuter = "w_tV";
export var lbContainerInner = "w_tW";
export var movingForwards = "w_tX";
export var movingForwardsOther = "w_tY";
export var movingBackwards = "w_tZ";
export var movingBackwardsOther = "w_t0";
export var lbImage = "w_t1";
export var lbOtherImage = "w_t2";
export var prevButton = "w_t3";
export var nextButton = "w_t4";
export var closing = "w_t5";
export var appear = "w_t6";